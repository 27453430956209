import useAuth from '@finam/takeprofit-widgets-core/useAuth';
import { Loader } from '@finam/web-ui-kit-components';
import { useEffect, useRef, useState } from 'react';

interface Props {
  onSend: (ott: string) => Promise<void>;
}

const App = ({ onSend }: Props) => {
  const { isLoggedIn, authWidget, oneTimeToken } = useAuth();

  const [loading, setLoading] = useState(false);

  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLoggedIn == null || isLoggedIn || nodeRef.current == null) {
      return undefined;
    }

    authWidget?.mount(nodeRef.current, { theme: 'dark', lang: 'en' });

    return () => {
      authWidget?.unmount();
    };
  }, [isLoggedIn, authWidget]);

  const processRef = useRef(false);

  useEffect(() => {
    if (isLoggedIn && !processRef.current) {
      const process = async () => {
        processRef.current = true;
        setLoading(true);
        try {
          const ott = await oneTimeToken();
          await onSend(ott);
        } catch (err) {
          console.error(err);
        } finally {
          processRef.current = false;
          setLoading(false);
        }
      };

      process();
    }
  }, [isLoggedIn, oneTimeToken, onSend]);

  return (
    <>
      {(isLoggedIn == null || loading) && <div className="loader-wrap"><Loader /></div>}
      <div className="auth-widget" ref={nodeRef} />
    </>
  );
};

export default App;
