export enum MessageType {
  WIDGET_OTT = 'WIDGET_OTT',
  WIDGET_SUBSCRIBE_ACTIONS = 'WIDGET_SUBSCRIBE_ACTIONS',
  WIDGET_ACTION = 'WIDGET_ACTION',
}

export interface Message {
  type: MessageType;
}

const WIDGETS_ORIGIN = new URL(WIDGETS_URL).origin;

const isWidgetMessage = (message: unknown): message is Message => (
  message != null &&
  typeof message === 'object' &&
  'type' in message &&
  MessageType[message.type as MessageType] != null
);

export const sendMessage = (
  source: MessageEventSource,
  message: Message,
  targetOrigin: string = WIDGETS_ORIGIN,
) => {
  source.postMessage(message, { targetOrigin });
};

export const subscribeMessages = (
  onMessage: (source: MessageEventSource, message: Message) => void,
  origin: string = WIDGETS_ORIGIN,
) => {
  const handleMessage = (event: MessageEvent) => {
    const message = event.data;
    if (event.origin !== origin || event.source == null || !isWidgetMessage(message)) {
      return;
    }

    onMessage(event.source, message);
  };

  window.addEventListener('message', handleMessage);

  return () => {
    window.removeEventListener('message', handleMessage);
  };
};
