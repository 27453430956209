import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { TakeProfit } from '@finam/web-ui-kit-core/theme';
import { sendOtt } from '@finam/takeprofit-widgets-core/ott';
import './index.css';
import App from './App';

const darkThemeClasses = [
  TakeProfit.baseClass,
  TakeProfit.defaultLayoutClass,
  TakeProfit.darkModeClass,
];

document.body.classList.add(...darkThemeClasses);

const root = ReactDOM.createRoot(document.getElementById('root')!);

const opener = window.opener as Window;

if (opener == null) {
  throw Error('opener is not found');
}

const handleSend = (ott: string) => {
  sendOtt(opener, ott);

  // таймаут нужен чтобы ott обработал раньше события закрытия страницы
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
      window.close();
    }, 200);
  });
};

root.render(
  <StrictMode>
    <App onSend={handleSend} />
  </StrictMode>,
);
