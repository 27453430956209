import { useCallback, useEffect, useRef, useState } from 'react';
import TxAuthWidget from '@finam/tx-auth-widget';
import { ANONYMOUS_PROVIDER, AUTH_VARIANT, PROVIDER } from './constants';

const useAuth = (withAnonymous = false) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [noAccount, setNoAccount] = useState<boolean>();
  const [authWidget, setAuthWidget] = useState<TxAuthWidget>();

  const widgetCreating = useRef(false);

  useEffect(() => {
    if (widgetCreating.current) {
      return;
    }
    widgetCreating.current = true;
    TxAuthWidget.new({
      variant: AUTH_VARIANT,
      appName: APP_NAME,
      appVersion: APP_VERSION,
      provider: withAnonymous ? [PROVIDER, ANONYMOUS_PROVIDER] : PROVIDER,
    })
      .then((widget) => {
        if (!withAnonymous || widget.isLoggedIn()) {
          setAuthWidget(widget);
        } else {
          widget.authorizeAnonymously().then(
            () => {
              setAuthWidget(widget);
            },
            (err) => {
              setAuthWidget(widget);
              console.error(err);
            },
          );
        }
      });
  }, [withAnonymous]);

  useEffect(() => {
    if (authWidget == null) {
      return undefined;
    }

    let renewError = false;

    const unsubscribe = authWidget.subscribeJWT(async (res) => {
      setIsLoggedIn(res != null && res.provider === PROVIDER);
      // TODO нужно ли проверять счета по каким то условиям?
      setNoAccount(res != null && !res.session.accounts.length);
      renewError = false;
    });

    if (withAnonymous) {
      const unsubscribeOnRenewError = authWidget.subscribeOnTokenRenewError(() => {
        renewError = true;
      });

      const unsubscribeOnLogout = authWidget.subscribeOnLogout(async () => {
        if (renewError) {
          renewError = false;
          await authWidget.authorizeAnonymously();
        } else {
          await authWidget.setProvider(undefined);
        }
      });

      return () => {
        unsubscribe();
        unsubscribeOnRenewError();
        unsubscribeOnLogout();
      };
    }

    return unsubscribe;
  }, [authWidget, withAnonymous]);

  const exchangeOneTimeToken = useCallback(async (ott: string) => {
    if (authWidget == null) {
      throw Error('widget is not created');
    }

    await authWidget.exchangeOneTimeToken(ott);
  }, [authWidget]);

  const oneTimeToken = useCallback(() => {
    if (authWidget == null) {
      throw Error('widget is not created');
    }

    return authWidget.oneTimeToken();
  }, [authWidget]);

  const logout = useCallback(async () => {
    if (authWidget == null) {
      throw Error('widget is not created');
    }

    if (!isLoggedIn) {
      return;
    }

    await authWidget.logout();
    await authWidget.authorizeAnonymously();
  }, [authWidget, isLoggedIn]);

  return {
    isLoggedIn,
    noAccount,
    authWidget,
    tokenProvider: authWidget?.getTokenProvider(),
    exchangeOneTimeToken,
    oneTimeToken,
    logout,
  };
};

export default useAuth;
