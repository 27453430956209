import { Message, MessageType, sendMessage, subscribeMessages } from './bus';

const AUTH_ORIGIN = new URL(AUTH_URL).origin;

interface OttMessage extends Message {
  type: MessageType.WIDGET_OTT;
  ott: string;
}

const isOttMessage = (message: Message): message is OttMessage => (
  message.type === MessageType.WIDGET_OTT
);

export const getOtt = () => (
  new Promise<string | undefined>((resolve, reject) => {
    const popup = window.open(AUTH_URL);

    if (popup == null) {
      reject(Error('auth popup is not opened'));
      return;
    }

    let unsubscribe: VoidFunction;
    let intervalId: number | undefined;
    const handleMessage = (source: MessageEventSource, message: Message) => {
      if (!isOttMessage(message)) {
        return;
      }

      unsubscribe();
      window.clearInterval(intervalId);
      resolve(message.ott);
    };

    unsubscribe = subscribeMessages(handleMessage, AUTH_ORIGIN);

    intervalId = window.setInterval(() => {
      if (popup.closed) {
        unsubscribe();
        window.clearInterval(intervalId);
        resolve(undefined);
      }
    }, 1000);
  })
);

export const sendOtt = (source: MessageEventSource, ott: string) => {
  const message: OttMessage = {
    type: MessageType.WIDGET_OTT,
    ott,
  };
  sendMessage(source, message);
};
